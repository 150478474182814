import React from "react";
import { useApiService } from "../../../../base/providers";
import { useParams } from "react-router-dom";
import { DspAuditBinSimple, DspAuditVariance, DspAuditVarianceChange, DspBinMismatch, GetDspAuditVarianceResponse } from "types/dsp/audits";
import { auditVarianceInitialState, AuditVarianceReducer } from "./reducer";
import { AuditVarianceTabType } from "../types";
import {CancelRescanRequest, CreateRescanRequest} from "types/dsp/auditRescan";

type DspAuditVarianceContextType = {
    auditId: string
    auditName: string
    auditVariances: DspAuditVariance[]
    binMismatches: DspBinMismatch[]
    hasHandwrites: boolean
    rescanIds: Set<number>
    selectedPending: Set<number>
    selectedRescan: Set<number>
    selectedTab: AuditVarianceTabType
    getQuantity: (lightyearId: number) => number
    requestRescan: () => void
    resetNewQuantity: (lightyearId: number) => void
    setNewQuantity: (lightyearId: number, newQuantity: number) => void
    toggleFilter: (lightyearId: number) => void
    togglePendingSelected: (lightyearId: number) => void
    toggleRescanSelected: (lightyearId: number) => void
    setSelectedTab: (index: AuditVarianceTabType) => void
    updateBinVariance: () => Promise<void>
    cancelRescan: (selectedLightyearIds: number[]) => void
}

const DspAuditVarianceContext = React.createContext<DspAuditVarianceContextType | undefined>(undefined);

type DspAuditVarianceProviderProps = {
    children: React.ReactNode
}

function DspAuditVarianceProvider(props: DspAuditVarianceProviderProps): JSX.Element {
    const { apiService } = useApiService();
    const { auditId } = useParams();
    const [state, dispatch] = React.useReducer(AuditVarianceReducer, auditVarianceInitialState);

    const hasHandwritesMemo: boolean = React.useMemo((): boolean => {
        if (!state.auditVariances || !state.auditVarianceQuantities) {
            return false;
        }

        const hasHandwrites = state.auditVariances.filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId))
            .some((value: DspAuditVariance): boolean => {
                const mapValue = state.auditVarianceQuantities.get(value.lightyearPartId);

                // DM 11/08/2024 Check for undefined: Instead of just mapValue, which would treat 0 
                // as falsy in a boolean context, it's better to explicitly check if mapValue is not undefined.
                // This ensures that even if the map holds 0 as a value, it will still be considered in the comparison.
                if (mapValue === undefined) {
                    return false;
                }

                return value.scannedCount !== mapValue;
            });

        return hasHandwrites;
    }, [state.auditVariances, state.auditVarianceQuantities, state.selectedPending]);

    const getAuditVariancesCallback = React.useCallback((): void => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditVariance(auditId)
            .then((response: GetDspAuditVarianceResponse) => dispatch({ type: "SET_AUDIT_VARIANCE", payload: response }))
            .catch((err) => console.error("Unable to get audit", err));
    }, [apiService, auditId]);

    const updateBinVarianceCallback = React.useCallback((): Promise<void> => {
        return new Promise((resolve, reject) => {
            const mappedVariances = state.auditVariances
                .filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId))
                .map((value: DspAuditVariance): DspAuditVarianceChange => {
                    return {
                        auditBinId: value.bins[0].binId,
                        lightyearId: value.lightyearPartId,
                        newOnHand: state.auditVarianceQuantities.get(value.lightyearPartId) || value.scannedCount
                    }; 
                });
            
            apiService.dsp.updateBinVariance(mappedVariances)
                .then(() => getAuditVariancesCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, getAuditVariancesCallback, state.auditVariances, state.auditVarianceQuantities, state.selectedPending]);

    const requestRescanCallback = React.useCallback((): void => {
        const createRescanRequests: CreateRescanRequest[] = state.auditVariances
            .filter((value: DspAuditVariance) => state.selectedPending.has(value.lightyearPartId))
            .flatMap((value: DspAuditVariance) => {
                return value.bins.map((valueInner: DspAuditBinSimple) => {
                    return {
                        auditBinId: valueInner.binId,
                        lightyearId: value.lightyearPartId,
                        partNumber: value.partNumber,
                    };
                });
            });

        apiService.auditRescan.createRescan(createRescanRequests)
            .then(() => getAuditVariancesCallback())
            .catch((err) => console.error("Unable to create rescans", err));
    }, [apiService.auditRescan, state.auditVariances, state.selectedPending, getAuditVariancesCallback]);

    const togglePendingSelectedCallback = React.useCallback((lightyearId: number) => {
        dispatch({ type: "TOGGLE_PENDING_SELECTED", payload: lightyearId });
    }, []);

    const toggleRescanSelectedCallback = React.useCallback((lightyearId: number) => {
        dispatch({ type: "TOGGLE_RESCAN_SELECTED", payload: lightyearId });
    }, []);

    const setNewQuantityCallback = React.useCallback((lightyearId: number, newQuantity: number): void => {
        dispatch({ type: "CHANGE_VARIANCE_QUANTITY", payload: { lightyearId, newQuantity } });
    }, []);

    const resetNewQuantityCallback = React.useCallback((lightyearId: number): void => {
        dispatch({ type: "RESET_VARIANCE_QUANTITY", payload: lightyearId });
    }, []);

    const getQuantityCallback = React.useCallback((lightyearId: number): number => {
        var quantity = state.auditVarianceQuantities.get(lightyearId);

        return quantity || 0;
    }, [state.auditVarianceQuantities]);

    const setSelectedTabCallback = React.useCallback((index: AuditVarianceTabType): void => {
        dispatch({ type: "SET_SELECTED_TAB", payload: index });
    }, []);

    const toggleFilterCallback = React.useCallback((lightyearId: number): void => {
        dispatch({ type: "TOGGLE_FILTER", payload: lightyearId });
    }, []);

    const cancelRescanCallback = React.useCallback((selectedLightyearIds: number[]): void => {
        const rescansToCancel = state.auditVariances
            .filter((value: DspAuditVariance) => selectedLightyearIds.includes(value.lightyearPartId))
            .map((value: DspAuditVariance): CancelRescanRequest => {
                return {obligationIds: value.obligationIds }
            });

        apiService.auditRescan.cancelRescan(rescansToCancel)
            .then(() => getAuditVariancesCallback())
            .catch((err) => console.error(err));
    }, [state.auditVariances, getAuditVariancesCallback, apiService.auditRescan]);

    React.useEffect(() => {
        getAuditVariancesCallback();
    }, [getAuditVariancesCallback]);

    return (
        <DspAuditVarianceContext.Provider
            value={{
                auditId: auditId || "",
                auditName: state.auditName,
                auditVariances: state.auditVariances,
                binMismatches: state.binMismatches,
                hasHandwrites: hasHandwritesMemo,
                rescanIds: state.rescanIds,
                selectedPending: state.selectedPending,
                selectedRescan: state.selectedRescan,
                selectedTab: state.selectedTab,
                getQuantity: getQuantityCallback,
                requestRescan: requestRescanCallback,
                resetNewQuantity: resetNewQuantityCallback,
                setNewQuantity: setNewQuantityCallback,
                toggleFilter: toggleFilterCallback,
                togglePendingSelected: togglePendingSelectedCallback,
                toggleRescanSelected: toggleRescanSelectedCallback,
                setSelectedTab: setSelectedTabCallback,
                updateBinVariance: updateBinVarianceCallback,
                cancelRescan: cancelRescanCallback,
            }}
            >
            {props.children}
        </DspAuditVarianceContext.Provider>
    );
}

function useDspAuditVarianceContext(): DspAuditVarianceContextType {
    const context = React.useContext(DspAuditVarianceContext);

    if (!context) {
        throw new Error("useDspAuditVarianceContext must be used within a DspAuditVarianceProvider");
    }

    return context;
}

export { DspAuditVarianceProvider, useDspAuditVarianceContext }