import React from "react";
import { Stack } from "@mui/material";
import { AcceptPendingVarianceButton } from "../acceptPendingVarianceButton";
import { RequestRescanButton } from "../requestRescanButton";
import { useDspAuditVarianceContext } from "../../context";
import {CancelRescanButton} from "../cancelRescanButton";

function OptionsResolver(): JSX.Element {
    const { selectedTab } = useDspAuditVarianceContext();
    const [component, setComponent] = React.useState<JSX.Element>(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (selectedTab) {
                case "pending":
                    return (
                        <Stack
                            direction="row"
                            spacing={2}
                            >
                            <RequestRescanButton key="request-rescan" />
                            <AcceptPendingVarianceButton key="accept-pending-variance" />
                        </Stack>
                    );
                case "rescans":
                    return (<CancelRescanButton key="cancel-rescan" />);
                default: 
                    return (<></>);
            }
        });
    }, [selectedTab]);

    return (component);
}

export { OptionsResolver }