import axios from "axios";

export class DealerFenixService {
    getIframeUrl(token: string, username: string, dealerId: string, locationId: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post("https://gateway.dealerfenix.com/admin_login_api/login/oauth2/dealerbuilt", {}, {
                    headers: {
                        "Accept": "*/*",
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "x-dealer-id": dealerId,
                        "x-location-id": locationId,
                        "x-username": "hosmer@0179", // TODO DM 01/21/2025 This is temporary! Please eventually fix once NADA 2025 demo done!
                    }
                });

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                let locationHeader = response.headers['location'];

                if (!locationHeader) {
                    throw new Error(`Response did not include Location header`);
                }
                
                if (locationHeader && !/^https?:\/\//i.test(locationHeader)) {
                    locationHeader = 'https://' + locationHeader;
                }

                resolve(locationHeader);
            } catch (err) {
                reject(err);
            }
        });
    }
}