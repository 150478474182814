import { Checkbox, MenuItem, Select } from "@mui/material";
import { BinStatusResolver } from "../../../resolver";
import { GridColDef } from "@mui/x-data-grid";
import { Utilities } from "../../../../../../../utilities";

type ColumnsParamsType = {
    selectedRows: Set<string>
    onChangeIsRowSelectedCallback: (binName: string) => void
}

function columns(columnsParams: ColumnsParamsType): GridColDef[] {
    return [
        {
            field: "id",
            headerName: "ID",
            hideable: true,
            width: 90
        },
        {
            field: "binName",
            flex: 1,
            headerName: "Bin",
            hideable: false
        },
        {
            field: "binStatus",
            flex: 1,
            headerName: "Status",
            hideable: false,
            renderCell: (params) => (<BinStatusResolver status={params.value} auditBinId={params.row.id} />)
        },
        {
            field: "uniqueParts",
            flex: 1,
            headerName: "Unique Parts",
            hideable: false
        },
        {
            field: "onHand",
            flex: 1,
            headerName: "On Hand",
            hideable: false
        },
        {
            field: "totalValue",
            flex: 1,
            headerName: "Total Value",
            hideable: false,
            valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
        },
        {
            field: "timeToScan",
            flex: 1,
            headerName: "Time To Scan",
            hideable: false,
            renderCell: (params) => {
                if (params.row.binStatus === 0) {
                    return (<span>-</span>);
                }

                return (<span>{params.value}</span>);
            }
        },
        {
            field: "submitted",
            flex: 1,
            headerName: "Submitted",
            hideable: false,
            renderCell: (params) => {
                if (params.row.binStatus === 0) {
                    return (<span>-</span>);
                }
    
                return (<span>{new Date(params.value).toLocaleString("en-US")}</span>);
            }
        },
        {
            field: "assigned",
            flex: 1.5,
            headerName: "Assigned",
            hideable: true,
            renderCell: (params) => {
                if (params.row.binStatus !== 0) {
                    return (<></>);
                }
    
                return (
                    <Select value={1}>
                        <MenuItem value={1}>Not Yet Implemented</MenuItem>
                    </Select>
                )
            }
        },
        {
            field: "isSelected",
            flex: 0.5,
            headerName: "",
            hideable: false,
            renderCell: (params) => (
                <Checkbox
                    checked={columnsParams.selectedRows.has(params.row.binName)}
                    disabled={params.row.binStatus !== 0}
                    onChange={() => columnsParams.onChangeIsRowSelectedCallback(params.row.binName)}
                    />
            )
        }
    ];
}

export { columns }