import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { LocationsDropdown } from "../locationsDropdown";

export type CreateLotFormValues = {
    locationId: string
    lotName: string
}

type CreateLotFormProps = {
    clickSubmitCallback: (formValues: CreateLotFormValues) => void
}

export function CreateLotForm(props: CreateLotFormProps): JSX.Element {
    const { clickSubmitCallback } = props;
    const [formValues, setFormValues] = React.useState<CreateLotFormValues>({
        locationId: "",
        lotName: ""
    });

    const setSelectedLocationCallback = React.useCallback((selectedLocation: string) => {
        setFormValues(prevState => ({
            ...prevState,
            locationId: selectedLocation
        }));
    }, []);

    const onChangeLotName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            lotName: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <LocationsDropdown
                selectedLocation={formValues.locationId}
                setSelectedLocationCallback={setSelectedLocationCallback}
                />
            <TextField
                id="lotName"
                label="Lot Name"
                onChange={onChangeLotName}
                value={formValues.lotName}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Save
            </Button>
        </Stack>
    );
}