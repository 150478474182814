import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { MenuButtonBase } from '../../base/menuButton';

export function PartsButton(): JSX.Element {
    return (
        <MenuButtonBase
            icon={<QrCodeScannerOutlinedIcon />}
            id="parts-menu-button"
            menuItems={[
                {
                    label: "Audits",
                    navigationPath: "/parts/audit"
                },
                {
                    label: "Labels",
                    navigationPath: "/parts/print"
                }
            ]}
            text="Parts"
            />
    );
}