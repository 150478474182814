import React from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "../../../../base/providers";
import { DspAudit, DspAuditBin, DspAuditBinReport, DspAuditPart, DspAuditVarianceReport, FinishAuditResponse, GetDspAuditDetailsResponse } from "types/dsp/audits";
import { auditDetailsInitialState, AuditDetailsReducer } from "./reducer";

type DspAuditDetailsContextType = {
    audit?: DspAudit
    auditBins: DspAuditBin[]
    auditName: string
    auditParts: DspAuditPart[]
    auditStatus: number
    auditVariances: DspAuditVarianceReport[]
    binReports: DspAuditBinReport[]
    canFinishAudit: boolean
    selectedBins: Set<string>
    selectedView: "variance" | "bins"
    changeIsRowSelected: (binName: string) => void
    changeSelectedView: (selectedView: "variance" | "bins") => void
    finishAudit: () => Promise<FinishAuditResponse>
    requestBinVarianceRecalculation: (auditBinId: number) => Promise<void>
}

const DspAuditDetailsContext = React.createContext<DspAuditDetailsContextType | undefined>(undefined);

type DspAuditDetailsProviderProps = {
    children: React.ReactNode
}

function DspAuditDetailsProvider(props: DspAuditDetailsProviderProps): JSX.Element {
    const { auditId } = useParams();
    const { apiService } = useApiService();
    const [state, dispatch] = React.useReducer(AuditDetailsReducer, auditDetailsInitialState);

    const getAuditDetailsCallback = React.useCallback(() => {
        if (!auditId) {
            return;
        }

        apiService.dsp.getAuditDetails(auditId)
            .then((response: GetDspAuditDetailsResponse) => dispatch({ type: "SET_AUDIT_DETAILS", payload: response }))
            .catch((err) => {
                console.error("Unable to get audit details", err);
            });
    }, [apiService, auditId]);

    const changeSelectedBinsCallback = React.useCallback((binName: string): void => {
        dispatch({ type: "CHANGE_SINGLE_BIN_SELECTION", payload: binName });
    }, []);

    const changeSelectedViewCallback = React.useCallback((selectedView: "variance" | "bins"): void => {
        dispatch({ type: "SET_SELECTED_VIEW", payload: selectedView });
    }, []);

    const finishAuditCallback = React.useCallback((): Promise<FinishAuditResponse> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.finishAudit(Number(auditId))
                .then((response: FinishAuditResponse) => resolve(response))
                .catch((err) => reject(err));
        });
    }, [apiService, auditId]);

    const requestBinVarianceRecalculationCallback = React.useCallback((auditBinId: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiService.dsp.recalculateVariance(Number(auditId), auditBinId)
                .then(() => getAuditDetailsCallback())
                .then(() => resolve())
                .catch((err) => reject(err));
        });
    }, [apiService, auditId, getAuditDetailsCallback]);

    React.useEffect(() => {
        getAuditDetailsCallback();
    }, [getAuditDetailsCallback]);

    return (
        <DspAuditDetailsContext.Provider
            value={{
                audit: state.audit,
                auditBins: state.auditBins,
                auditName: state.audit?.auditName || "",
                auditParts: state.auditParts,
                auditStatus: state.audit?.status || 0,
                auditVariances: state.auditVariances,
                binReports: state.binReports,
                canFinishAudit: state.canFinishAudit,
                selectedBins: state.selectedBins,
                selectedView: state.selectedView,
                changeIsRowSelected: changeSelectedBinsCallback,
                changeSelectedView: changeSelectedViewCallback,
                finishAudit: finishAuditCallback,
                requestBinVarianceRecalculation: requestBinVarianceRecalculationCallback,
            }}
            >
            {props.children}
        </DspAuditDetailsContext.Provider>
    );
}

function useDspAuditDetailsContext(): DspAuditDetailsContextType {
    const context = React.useContext(DspAuditDetailsContext);

    if (!context) {
        throw new Error("useDspAuditDetailsContext must be used within a DspAuditDetailsProvider");
    }

    return context;
}

export { DspAuditDetailsProvider, useDspAuditDetailsContext }