import React from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { ContentContainer } from "./contentContainer";
import { NavBarContextProvider, SideNavBar } from "../navBar";

type TitleMap = {
    [key: string]: string
}

const titleMap: TitleMap = {
    "/vehicles": "Vehicles",
    "/parts": "Parts"
};

export function Layout(): JSX.Element {
    const location = useLocation();

    React.useEffect(() => {
        const pathName = location.pathname;

        const mappedTitle = Object.keys(titleMap).find((key: string) => pathName.toUpperCase().startsWith(key.toUpperCase()));

        if (!mappedTitle) {
            document.title = "DealerBuilt Scan Web";
            return;
        }

        document.title = `DealerBuilt Scan Web - ${titleMap[mappedTitle]}`;
    }, [location]);

    return (
        <NavBarContextProvider>
            <Box sx={{ display: "flex" }}>
                <SideNavBar />
                <Box
                    sx={{
                        flexGrow: 1,
                        paddingBottom: 5,
                        paddingTop: 5,
                        }}
                        >
                        <ContentContainer />
                </Box>
            </Box>
        </NavBarContextProvider>
    )
}