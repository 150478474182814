import { NavButtonBase } from "../button";
import SettingsIcon from '@mui/icons-material/Settings';

type SettingsButtonBaseProps = {
    navigateDestination: string
}

export function SettingsButtonBase({ navigateDestination }: SettingsButtonBaseProps): JSX.Element {
    return (
        <NavButtonBase
            icon={<SettingsIcon />}
            navigateDestination={navigateDestination}
            text="Settings"
            />
    )
}