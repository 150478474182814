import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { useApiService } from "../../../../../base/providers";
import { DspPrinter } from "types/dsp/setup";
import { PrinterTypeDropdown } from "../typeDropdown";

export type EditPrinterFormValues = {
    id: number
    printerType: string
    printerName: string
    printerShortName: string
    host: string
    port: string
}

type CreateUpdatePrinterFormProps = {
    id: number
    clickSubmitCallback: (formValues: EditPrinterFormValues) => void
}

export function EditPrinterForm(props: CreateUpdatePrinterFormProps): JSX.Element {
    const { clickSubmitCallback } = props;
    const { apiService } = useApiService();
    const [formValues, setFormValues] = React.useState<EditPrinterFormValues>({
        id: -1,
        printerType: "",
        printerName: "",
        printerShortName: "",
        host: "",
        port: ""
    });

    React.useEffect(() => {
        const { id } = props;

        apiService.dsp.getPrinterById(id)
            .then((dspPrinter: DspPrinter) => {
                setFormValues({
                    id: dspPrinter.id,
                    printerType: dspPrinter.printerType.toString(),
                    printerName: dspPrinter.name,
                    printerShortName: dspPrinter.shortName,
                    host: dspPrinter.host,
                    port: dspPrinter.port.toString()
                });
            })
            .catch((err: any) => console.error(`Unable to get audit location with id ${id}`, err));
    }, [props, setFormValues, apiService]);

    const setSelectedPrinterTypeCallback = React.useCallback((selectedPrinterType: string) => {
        setFormValues(prevState => ({
            ...prevState,
            printerType: selectedPrinterType
        }));
    }, []);

    const onChangePrinterName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            printerName: e.target.value || ""
        }));
    }

    const onChangePrinterShortName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            printerShortName: e.target.value || ""
        }));
    }

    const onChangeHost = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            host: e.target.value || ""
        }));
    }

    const onChangePort = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            port: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <PrinterTypeDropdown
                selectedType={formValues.printerType}
                setSelectedTypeCallback={setSelectedPrinterTypeCallback}
                />
            <TextField
                id="printerName"
                label="Name"
                onChange={onChangePrinterName}
                value={formValues.printerName}
                />
            <TextField
                id="printerShortName"
                label="Short Name"
                onChange={onChangePrinterShortName}
                value={formValues.printerShortName}
                />
            <TextField
                id="host"
                label="Host"
                onChange={onChangeHost}
                value={formValues.host}
                />
            <TextField
                id="port"
                label="Port"
                onChange={onChangePort}
                value={formValues.port}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Save
            </Button>
        </Stack>
    );
}