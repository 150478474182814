import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useSettingsContext } from '../context';
import { SettingsTabType } from '../context/types';

export function TabView(): JSX.Element {
    const { selectedTab, selectTab } = useSettingsContext();

    const handleChange = (e: React.SyntheticEvent, newValue: SettingsTabType) => {
        selectTab(newValue);
    };

    return (
        <Box>
            <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="options menu tabs"
                >
                <Tab
                    label="Parts Print"
                    value="parts-print"
                    />
                <Tab
                    label="Parts Label Layouts"
                    value="parts-label-layouts"
                    />
                <Tab
                    label="Vehicle Lots"
                    value="vehicle-lots"
                    />
                <Tab
                    label="Vehicle Label Layouts"
                    value="vehicle-label-layouts"
                    />
            </Tabs>
        </Box>
    );
}