import { useAuth } from "../../../../../../providers";
import { NavButtonBase } from "../../base";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export function LogOutButton(): JSX.Element {
    const { user, logOut } = useAuth();
    return (
        <NavButtonBase
            icon={<PersonOutlineOutlinedIcon />}
            secondaryText="Log Out"
            text={user?.userFullName || ""}
            onClickNav={logOut}
            />
    );
}