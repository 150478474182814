import { AppBar } from "@mui/material";
import { PermanentDrawerLeft } from "./drawer";
import { useNavBarContext } from "./context";
import { NavToggleButton } from "./navButtons/buttons/toggleButton";

export function SideNavBar(): JSX.Element {
    const { drawerWidth } = useNavBarContext();

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`
                }}
                >
                <PermanentDrawerLeft />
            </AppBar>
            <NavToggleButton />
        </>
    );
}