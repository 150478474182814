import React from "react";
import { useAuth } from "../../../../../../../providers";
import { Utilities } from "../../../../../../../../../utilities";
import { jwtDecode } from "jwt-decode";
import { User } from "../../../../../../../providers/authProvider";

type LogInContextType = {
    handleLoginPopup: () => void
}

const LogInContext = React.createContext<LogInContextType | undefined>(undefined);

type LogInContextProviderProps = {
    children: React.ReactNode
}

function LogInContextProvider(props: LogInContextProviderProps): JSX.Element {
    const { isLoggedIn, user, logIn, logOut } = useAuth();

    const handleLoginMessageCallback = React.useCallback((data: any, popup: Window): void => {
        logIn(data);
        popup.close();
    }, [logIn]);

    const openLoginPopupCallback = React.useCallback((url: string): void => {
        Utilities.openLoginPopup(url, window.screen)
            .then((popup: Window) => {
                return Utilities.hookLoginPopup(popup, url, handleLoginMessageCallback)
            })
            .catch((err: any) => console.error("An error occurred while handling the login popup.", err));
    }, [handleLoginMessageCallback]);

    const handleLoginPopupCallback = React.useCallback((): void => {
        try {
            const loginUrl = process.env.REACT_APP_LOGIN_URL || undefined;

            if (!loginUrl) {
                throw Error("Login url was not defined in environment variables.");
            }

            openLoginPopupCallback(loginUrl);
        } catch (err: any) {
            console.error(err);
        }
    }, [openLoginPopupCallback]);

    const checkTokenExpiration = React.useCallback((userObj: User | undefined): boolean => {
        if (!userObj) {
            logOut();
            return false;
        }

        const decodedToken = jwtDecode(userObj.accessToken);

        console.log(decodedToken);

        if (!decodedToken || !decodedToken.exp) {
            return false;
        }

        const isExpired = decodedToken.exp * 1000 < Date.now();

        if (isExpired) {
            logOut();
            return false;
        }

        return true;
    }, [logOut]);

    React.useEffect(() => {
        if (isLoggedIn) {
            checkTokenExpiration(user);
            return;
        }
        
        const userStr = localStorage.getItem("scan_web_auth");

        if (!userStr || userStr.length === 0) {
            return;
        }

        const userObj = JSON.parse(userStr) as User;
        if (!userObj) {
            return;
        }

        if (!checkTokenExpiration(userObj)) {
            return;
        }

        logIn(userObj);
    }, [isLoggedIn, user, logIn, checkTokenExpiration]);
    
    return (
        <LogInContext.Provider
            value={{
                handleLoginPopup: handleLoginPopupCallback
            }}
            >
            {props.children}
        </LogInContext.Provider>
    );
}

function useLogInContext(): LogInContextType {
    const context = React.useContext(LogInContext);

    if (!context) {
        throw new Error("useLogInContext must be used within a LogInContextProvider");
    }

    return context;
}

export { LogInContextProvider, useLogInContext }