import React from "react";
import { navBarInitialState, NavBarReducer } from "./reducer";

type NavBarContextType = {
    drawerWidth: number
    isOpen: boolean
    toggleIsOpen: () => void
}

const NavBarContext = React.createContext<NavBarContextType | undefined>(undefined);

type NavBarContextProviderProps = {
    children: React.ReactNode
}

export function NavBarContextProvider(props: NavBarContextProviderProps): JSX.Element {
    const [state, dispatch] = React.useReducer(NavBarReducer, navBarInitialState);

    const toggleIsOpenCallback = React.useCallback((): void => {
        dispatch({ type: "TOGGLE_IS_OPEN" });
    }, []);

    return (
        <NavBarContext.Provider
            value={{
                drawerWidth: 240,
                isOpen: state.isOpen,
                toggleIsOpen: toggleIsOpenCallback
            }}
            >
            {props.children}
        </NavBarContext.Provider>
    );
}

export function useNavBarContext(): NavBarContextType {
    const context = React.useContext(NavBarContext);

    if (!context) {
        throw new Error("useNavBarContext must be used within a NavBarContextProvider");
    }

    return context;
}