import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { NavButtonBase } from '../../base';
import { useAdminContext } from '../../../../../../providers';

export function AdminButton(): JSX.Element {
    const { isAdmin } = useAdminContext();
    
    return (
        <NavButtonBase
            icon={<AdminPanelSettingsOutlinedIcon />}
            isVisible={isAdmin}
            navigateDestination="/admin"
            text="Admin"
            />
    );
}