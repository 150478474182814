import { createTheme } from "@mui/material";

export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 2560,
        }
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#0A2E49",
                    borderRight: "2px solid #E6E3ED",
                    color: "#ffffff",
                    ".MuiListItemButton-root:hover": {
                        backgroundColor: "#052135",
                        borderRadius: 8,
                    },
                    "& .MuiDivider-root": {
                        borderColor: "#ffffff"
                    },
                    "& .MuiInputLabel-root": {
                        color: "#ffffff",
                    },
                    "& .MuiInputBase-root": {
                        color: "#ffffff",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#ffffff"
                    },
                    "& .MuiSvgIcon-root": {
                        color: "#ffffff",
                    },
                    ".MuiIconButton-root:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.15)",
                    },
                    "& .MuiTypography-body2": {
                        color: "#b0b0b0"
                    },
                    "& .MuiDivider-middle": {
                        border: "2px solid #031C2F"
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#052236",
                    borderRadius: 6,
                    color: "#ffffff",
                    width: "201px",
                    "& .MuiMenuItem-root": {
                        height: "43px",
                    },
                    ".MuiMenuItem-root:hover": {
                        backgroundColor: "#0D47A1",
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#0A2E49"
        }
    }
});