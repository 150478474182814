import { createBrowserRouter } from "react-router-dom"

import { AdminRoutes } from "./components/admin";

import { Layout } from "./components/base";

import { PartsRoutes } from "./components/dsp";
import {
    AuditsView,
    AuditDetailsView,
    BinSelectionView,
    VarianceView
} from "./components/dsp/audit";

import { VehicleRoutes } from "./components/dsv";
import { AuditDetails } from "./components/dsv/audit";
import { DsvPrintView } from "./components/dsv/print";
import { CombinedSetup } from "./components/dsv/setup";

import { Options } from "./components/options";
import { AdminCreateForm } from "./components/admin/view/form/create";
import { AdminEditForm } from "./components/admin/view/form/edit";
import { AdminFeatureFlagView } from "./components/admin/view";
import { AllVehicleAudits } from "./components/dsv/home";
import { SettingsView } from "./components/settings";
import { PartsLabelView } from "./components/dsp/partsLabel";
import { ReportingView } from "./components/reporting";

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "",
                element: <Options />,
            },
            {
                path: "/admin",
                element: <AdminRoutes />,
                children: [
                    {
                        path: "/admin",
                        element: <AdminFeatureFlagView />
                    },
                    {
                        path: "/admin/create",
                        element: <AdminCreateForm />
                    },
                    {
                        path: "/admin/update/:dealerId/:locationId",
                        element: <AdminEditForm />
                    }
                ]
            },
            {
                path: "/parts",
                element: <PartsRoutes />,
                children: [
                    {
                        path: "/parts",
                        element: <AuditsView />
                    },
                    {
                        path: "/parts/audit",
                        element: <AuditsView />
                    },
                    {
                        path: "/parts/audit/:auditId",
                        element: <AuditDetailsView />
                    },
                    {
                        path: "/parts/audit/selection/:auditId",
                        element: <BinSelectionView />
                    },
                    {
                        path: "/parts/audit/:auditId/variance",
                        element: <VarianceView />
                    },
                    {
                        path: "/parts/print",
                        element: <PartsLabelView />
                    }
                ]
            },
            {
                path: "/vehicles",
                element: <VehicleRoutes />,
                children: [
                    {
                        path: "/vehicles",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits",
                        element: <AllVehicleAudits />
                    },
                    {
                        path: "/vehicles/audits/:auditId",
                        element: <AuditDetails />
                    },
                    {
                        path: "/vehicles/print",
                        element: <DsvPrintView />
                    },
                    {
                        path: "/vehicles/setup",
                        element: <CombinedSetup />
                    }
                ]
            },
            {
                path: "/reporting",
                element: <ReportingView />,
            },
            {
                path: "/settings",
                element: <SettingsView />
            }
        ]
    }
]);

export { appRouter };