import { CSSObject, Theme } from "@mui/material";

type ClosedStyleProps = {
    theme: Theme
}

export function ClosedStyle({ theme }: ClosedStyleProps): CSSObject {
    return {
        overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: `calc(${theme.spacing(7)} + 1px)`,
    };
}