import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNavBarContext } from "../../../../context";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

type NavButtonBaseProps = {
    icon: React.ReactNode
    id?: string
    isMenuButton?: boolean
    isVisible?: boolean
    navigateDestination?: string
    secondaryText?: string
    text: string
    onClickNav?: React.MouseEventHandler<HTMLDivElement>
}

export function NavButtonBase({
    icon,
    id,
    isMenuButton,
    isVisible,
    navigateDestination,
    secondaryText,
    text,
    onClickNav
}: NavButtonBaseProps): JSX.Element {
    const { isOpen } = useNavBarContext();
    const navigate = useNavigate();

    const onClickButton = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (onClickNav) {
            onClickNav(e);
        } else if (navigateDestination) {
            navigate(navigateDestination);
        } else {
            throw new Error("Either onClickNav or navigateDestination must be set!");
        }
    }

    return (
        <ListItem
            id={id}
            sx={{
                display: isVisible !== false ? "block" : "none",
            }}
            >
            <ListItemButton
                onClick={onClickButton}
                sx={{
                    justifyContent: isOpen ? "initial" : "center",
                    minHeight: 48,
                    px: 2.5,
                }}
                >
                <ListItemIcon
                    sx={{
                        justifyContent: "center",
                        minWidth: 0,
                        mr: isOpen ? 3 : "auto"
                    }}
                    >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    secondary={secondaryText}
                    sx={{
                        opacity: isOpen ? 1 : 0
                    }}
                    />
                {isMenuButton && <ChevronRightIcon />}
            </ListItemButton>
        </ListItem>
    );
}