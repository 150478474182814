import { Box, Divider, Drawer, Stack, Toolbar, useTheme } from "@mui/material";
import { useNavBarContext } from "../context";
import { NavBarTitle } from "../title";
import { NavAuthView } from "../navButtons";
import { AuthButtonView } from "../navButtons/buttons/auth";
import { ClosedStyle, OpenStyle } from "./style";


export function PermanentDrawerLeft(): JSX.Element {
    const { drawerWidth, isOpen } = useNavBarContext();
    const theme = useTheme();

    return (
        <Drawer
            open={isOpen}
            sx={{
                boxSizing: "border-box",
                flexShrink: 0,
                width: drawerWidth,
                '& .MuiDrawer-paper': isOpen ? OpenStyle({ drawerWidth, theme }) : ClosedStyle({ theme })
            }}
            variant="permanent"
            >
            <Toolbar>
                <Stack>
                    <NavBarTitle />
                </Stack>
            </Toolbar>
            <Divider variant="middle" />
            <Box flexGrow={1}>
                <NavAuthView />
            </Box>
            <Divider variant="middle" />
            <AuthButtonView />
        </Drawer>
    );
}