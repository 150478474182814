import { Button, Chip } from "@mui/material";
import { useDspAuditVarianceContext } from "../../context";

function RequestRescanButton(): JSX.Element {
    const { selectedPending, requestRescan } = useDspAuditVarianceContext();
    
    const onClickSelectRescans = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        
        requestRescan();
    }

    return (
        <Button
            onClick={onClickSelectRescans}
            variant="outlined"
            >
            <Chip label={selectedPending.size} />&nbsp;Re-Scan
        </Button>
    );
}

export { RequestRescanButton }