import { SettingsTabType } from "./types";

export type SettingsState = {
    selectedTab: SettingsTabType
};

type SettingsAction =
    | { type: 'SET_TAB'; payload: SettingsTabType }
    | { type: 'SET_LANGUAGE'; payload: string };

export const settingsReducer = (state: SettingsState, action: SettingsAction): SettingsState => {
    switch (action.type) {
        case 'SET_TAB':
            return {
                ...state,
                selectedTab: action.payload,
            };
        default:
            return state;
    }
};