import React from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useApiService, useAuth} from "../../../../../base/providers";
import { GetLocationsResponse, ServiceLocation } from "types/auth/locations";

type LocationsDropdownProps = {
    selectedLocation: string
    setSelectedLocationCallback: (value: string) => void
}

export function LocationsDropdown(props: LocationsDropdownProps): JSX.Element {
    const { selectedLocation, setSelectedLocationCallback } = props;
    const { user } = useAuth();
    const { apiService } = useApiService();

    const [locationsObj, setLocationsObj] = React.useState<GetLocationsResponse>({
        locations: [],
        defaultLocation: {
            location: "",
            foundDefaultLocation: false,
            userName: ""
        }
    });
    const [optionsElements, setOptionsElements] = React.useState<JSX.Element[]>([]);
    
    React.useEffect(() => {
        if (!user) {
            return;
        }

        const { username } = user;
        apiService.dsv.getLocations(username)
            .then((getLocationsResponse) => setLocationsObj(getLocationsResponse))
            .catch((err) => console.log("Unable to get locations", err));
    }, [user, apiService, setSelectedLocationCallback]);

    React.useEffect(() => {
        if (selectedLocation && selectedLocation.length > 0) {
            return;
        }

        if (!locationsObj.defaultLocation.foundDefaultLocation) {
            return;
        }

        setSelectedLocationCallback(locationsObj.defaultLocation.location);
    }, [locationsObj, selectedLocation, setSelectedLocationCallback]);

    React.useEffect(() => {
        if (!locationsObj || !locationsObj.locations) {
            return;
        }

        setOptionsElements(locationsObj.locations.map((location: ServiceLocation, index: number) => (
            <MenuItem key={index} value={`${location.slSetupLocationId}`}>
                {location.slSetupLocationId} - {location.name}
            </MenuItem>
        )));
    }, [locationsObj]);

    const onChangeSelectedValue = (e: SelectChangeEvent): void => {
        e.preventDefault();

        setSelectedLocationCallback(e.target.value);
    }

    return (
        <FormControl>
            <InputLabel id="printer-type-dropdown-label">Location Name</InputLabel>
            <Select
                id="printerType"
                labelId="printer-type-dropdown-label"
                label="LocationName"
                onChange={onChangeSelectedValue}
                value={selectedLocation}
                >
                {optionsElements}
            </Select>
        </FormControl>
    );
}