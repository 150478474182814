import { List } from "@mui/material";
import React from "react";
import { useAuth } from "../../../../../providers";
import { LogOutButton } from "./logOut";
import { LogInButton } from "./logIn";
import { LogInContextProvider } from "./logIn/context";

export function AuthButtonView(): JSX.Element {
    const { isLoggedIn } = useAuth();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch(isLoggedIn) {
                case true:
                    return <LogOutButton />
                case false:
                    return <LogInButton />
            }
        });
    }, [isLoggedIn]);

    return (
        <List>
            <LogInContextProvider>
                {component}
            </LogInContextProvider>
        </List>
    );
}