import React from 'react';
import { initialState, ReportingReducer } from './reducer';
import { DealerFenixService } from '../../../services/dealerfenixService';
import { useAuth } from '../../base';

interface ReportingContextProps {
    url: string
}

const ReportingContext = React.createContext<ReportingContextProps | undefined>(undefined);

export const ReportingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuth();
    const [state, dispatch] = React.useReducer(ReportingReducer, initialState);

    React.useEffect(() => {
        if (!user) {
            return;
        }

        const service = new DealerFenixService();
        service.getIframeUrl(user.accessToken, user.username, user.dealerId, user.locationId)
            .then((location: string) => dispatch({ type: 'SET_URL', payload: location }))
            .catch((err: any) => console.error("Unable to startup DealerFenix integration", err));
    }, [user]);

    return (
        <ReportingContext.Provider
            value={{
                url: state.url,
            }}
            >
            {children}
        </ReportingContext.Provider>
    );
};

export const useReporting = (): ReportingContextProps => {
    const context = React.useContext(ReportingContext);
    if (!context) {
        throw new Error('useReporting must be used within a ReportingProvider');
    }
    return context;
};