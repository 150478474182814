import React from "react";
import { Stack, TextField, FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent } from "@mui/material";

export type PrintPartsLabelFormValues = {
    stockNumbersStr: string
    labelCount: number
    labelType: "1" | "24"
}

type PrintFormProps = {
    clickSubmitCallback: (formValues: PrintPartsLabelFormValues) => void
}

export function PrintForm(props: PrintFormProps): JSX.Element {
    const [formValues, setFormValues] = React.useState<PrintPartsLabelFormValues>({
        stockNumbersStr: "",
        labelCount: 1,
        labelType: "1",
    });

    const onChangeStockNumbers = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        
        setFormValues(prevState => ({
            ...prevState,
            stockNumbersStr: e.target.value || ""
        }));
    }

    const onChangeLabelType = (e: SelectChangeEvent): void => {
        e.preventDefault();

        const newValue = e.target.value;
        if (newValue !== "1" && newValue !== "24") {
            return;
        }

        setFormValues(prevState => ({
            ...prevState,
            labelType: newValue
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        props.clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <TextField
                id="stockNumbers"
                label="Stock Number(s)"
                multiline={true}
                onChange={onChangeStockNumbers}
                placeholder="Example: A77533, R31101"
                rows={4}
                value={formValues.stockNumbersStr}
                />
            <FormControl>
                <InputLabel id="label-type-select-label">Label Type</InputLabel>
                <Select
                    id="labelType"
                    label="Label Type"
                    labelId="label-type-select-label"
                    onChange={onChangeLabelType}
                    value={formValues.labelType}
                    >
                    <MenuItem value="1">Stock Label</MenuItem>
                    <MenuItem value="24">Bin Label</MenuItem>
                </Select>
            </FormControl>
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Print Label(s)
            </Button>
        </Stack>
    );
}