import React from "react";
import { Box } from "@mui/material";
import { useReporting } from "../context";

export function DealerFenixIframe(): JSX.Element {
    const { url } = useReporting();

    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (!url || url === "") {
                return <></>;
            }

            return (
                <iframe
                    scrolling="no"
                    src={url}
                    style={{
                        border: "none",
                        height: "100vh",
                        position: "relative",
                        top: "-50px",
                        width: "100%",
                    }}
                    title="DealerFenix Reporting"
                    />
            );
        })
    }, [url]);

    return (
        <Box
            sx={{
                height: "100vh",
                overflow: "hidden",
                width: "100%",
            }}
            >
            {component}
        </Box>
    );
}