import { GridColDef } from "@mui/x-data-grid";
import { DspAuditBinSimple } from "types/dsp/audits";
import { Utilities } from "../../../../../../../utilities";
import { NewQuantityInput } from "./newQuantityInput";
import { FlagsColumn } from "./flags";
import { VarianceSelectedCheckbox } from "./checkbox";

export const pendingColumns: GridColDef[] = [
    {
        field: "check",
        filterable: false,
        flex: 0.3,
        headerName: "",
        hideable: false,
        renderCell: (params) => (<VarianceSelectedCheckbox lightyearId={params.row.id} />),
        resizable: false,
        sortable: false,
    },
    {
        field: "partNumber",
        filterable: false,
        flex: 1.25,
        headerName: "Part Number",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        cellClassName: "cellWordWrap",
        field: "bins",
        filterable: false,
        flex: 0.75,
        headerName: "Bin(s)",
        hideable: false,
        resizable: false,
        sortable: true,
        valueFormatter: (value: DspAuditBinSimple[]) => value.map((binObj: DspAuditBinSimple) => `${binObj.binName} (${binObj.scanCount})`).join(", ")
    },
    {
        field: "vendor",
        filterable: false,
        flex: 0.75,
        headerName: "Vendor",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "onHandCount",
        filterable: false,
        flex: 1.25,
        headerName: "On Hand Count",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "dollarVariance",
        filterable: false,
        flex: 1,
        headerName: "$ Variance",
        hideable: false,
        resizable: false,
        sortable: true,
        valueFormatter: (value: number) => Utilities.displayAsCurrency(value)
    },
    {

        field: "quantityVariance",
        filterable: false,
        flex: 1,
        headerName: "Qty Variance",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "scannedCount",
        filterable: false,
        flex: 0.75,
        headerName: "Scanned",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "movementSinceScan",
        filterable: false,
        flex: 1,
        headerName: "Movement",
        hideable: false,
        resizable: false,
        sortable: true,
    },
    {
        field: "newQuantity",
        filterable: false,
        flex: 1.25,
        headerName: "New Qty",
        hideable: false,
        renderCell: (params) => (<NewQuantityInput lightyearId={params.row.id} />),
        resizable: false,
        sortable: false,
    },
    {
        field: "varianceFlags",
        filterable: false,
        flex: 1.5,
        headerName: "",
        hideable: false,
        renderCell: (params) => (
            <FlagsColumn
                varianceFlags={{
                    onHoldCount: params.row.onHoldCount,
                    openOrderCount: params.row.openOrderCount,
                    hasMultipleBinsAssigned: params.row.hasMultipleBinsAssigned,
                    hasSupersession: params.row.hasSupersessions,
                }}
                />
        ),
        resizable: false,
        sortable: false,
    },
    {
        field: "filter",
        filterable: false,
        flex: 1,
        headerName: "",
        hideable: true,
        resizable: false,
        sortable: false,
    }
];