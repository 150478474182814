import { CSSObject, Theme } from "@mui/material";

type OpenStyleProps = {
    drawerWidth: number,
    theme: Theme
}

export function OpenStyle({ drawerWidth, theme }: OpenStyleProps): CSSObject {
    return {
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.enteringScreen,
            easing: theme.transitions.easing.sharp,
        }),
        width: drawerWidth
    };
}