type NavBarStateType = {
    isOpen: boolean
}

type NavBarReducerActionType =
    | { type: "TOGGLE_IS_OPEN" }

const navBarInitialState: NavBarStateType = {
    isOpen: true,
};

function NavBarReducer(state: NavBarStateType, action: NavBarReducerActionType): NavBarStateType {
    switch (action.type) {
        case "TOGGLE_IS_OPEN":
            return {
                ...state,
                isOpen: !state.isOpen
            };
    }
}

export {
    navBarInitialState,
    NavBarReducer
}