import React from "react";
import { NavButtonBase } from "../../base/button";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

export function ReportingButton(): JSX.Element {
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            if (!process.env.REACT_APP_API_URL) {
                return <></>;
            }

            if (process.env.REACT_APP_API_URL === "https://cloudapi.dealerbuilt.com/scan/v2/production") {
                return <></>;
            }

            return (
                <NavButtonBase
                    icon={<AssessmentOutlinedIcon />}
                    navigateDestination="/reporting"
                    text="Business Intelligence"
                    />
            );
        })
    }, []);

    return (component);
}