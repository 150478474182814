import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { MenuButtonBase } from "../../base/menuButton";

export function VehiclesButton(): JSX.Element {
    return (
        <MenuButtonBase
            icon={<LabelOutlinedIcon />}
            id="vehicles-menu-button"
            menuItems={[
                {
                    label: "History",
                    navigationPath: "/vehicles/audits"
                },
                {
                    label: "Labels",
                    navigationPath: "/vehicles/print"
                }
            ]}
            text="Vehicles"
            />
    );
}