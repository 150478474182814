import { AuthProvider } from './components/base';
import { AdminProvider, ApiServiceProvider } from './components/base/providers';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './appRouter';
import { FeatureFlagProvider } from './components/base/providers/featureFlag';

function App(): JSX.Element {
    return (
        <AuthProvider>
            <ApiServiceProvider>
                    <FeatureFlagProvider>
                        <AdminProvider>
                            <RouterProvider router={appRouter} />
                        </AdminProvider>
                    </FeatureFlagProvider>
            </ApiServiceProvider>
        </AuthProvider>
    );
}

export { App };
