import React from "react";
import { useDspAuditVarianceContext } from "../../context";
import { MappedRow } from "./types";
import { columns } from "./columns";
import { DspAuditBinSimple, DspBinMismatch } from "types/dsp/audits";
import { GenericDataGrid } from "../../../../../base/dataGrid";

export function MismatchDataGrid(): JSX.Element {
    const { binMismatches } = useDspAuditVarianceContext();

    const mappedRows = React.useMemo((): MappedRow[] => {
        if (!binMismatches) {
            return [];
        }

        return binMismatches.map((value: DspBinMismatch, index: number) => {
            return {
                ...value,
                id: index,
                matchedBins: value.scannedBins.filter((valueInner: DspAuditBinSimple) => valueInner.isMismatched === false),
                mismatchedBins: value.scannedBins.filter((valueInner: DspAuditBinSimple) => valueInner.isMismatched === true)
            };
        });
    }, [binMismatches]);

    return (
        <GenericDataGrid
            columns={columns}
            getRowHeight={() => "auto"}
            rows={mappedRows}
            sx={{
                "& .MuiDataGrid-cell": {
                    alignItems: "center",
                    display: "flex",
                },
                "& .cellWordWrap": {
                    overflow: "visible",
                    textOverflow: "clip",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                },
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }
            }}
            />
    )
}