import React from "react";
import { useSettingsContext } from "../context";
import { PartsPrintView } from "./partsPrint";
import { PartsLabelLayoutsView } from "./partsLabelLayouts";
import { VehicleLabelLayoutsView } from "./vehicleLabelLayouts";
import { VehicleLotsView } from "./vehicleLots";

export function SettingsContentView(): JSX.Element {
    const { selectedTab } = useSettingsContext();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch(selectedTab) {
                case "parts-print":
                    return <PartsPrintView />;
                case "parts-label-layouts":
                    return <PartsLabelLayoutsView />;
                case "vehicle-lots":
                    return <VehicleLotsView />;
                case "vehicle-label-layouts":
                    return <VehicleLabelLayoutsView />;
            }
        })
    }, [selectedTab]);

    
    return (component);
}