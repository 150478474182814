import React from "react";
import { useAuth } from "../../../providers";
import { NavButtonsView } from "./buttons";

export function NavAuthView(): JSX.Element {
    const { isLoggedIn } = useAuth();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (isLoggedIn) {
                case true:
                    return <NavButtonsView />;
                case false:
                    return <></>;
            }
        });
    }, [isLoggedIn]);

    return (component);
}