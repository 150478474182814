import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { PrinterTypeDropdown } from "../typeDropdown";

export type CreatePrinterFormValues = {
    printerType: string
    printerName: string
    printerShortName: string
    host: string
    port: string
}

type CreatePrinterFormProps = {
    clickSubmitCallback: (formValues: CreatePrinterFormValues) => void
}

export function CreatePrinterForm(props: CreatePrinterFormProps): JSX.Element {
    const { clickSubmitCallback } = props;
    const [formValues, setFormValues] = React.useState<CreatePrinterFormValues>({
        printerType: "",
        printerName: "",
        printerShortName: "",
        host: "",
        port: ""
    });

    const setSelectedPrinterTypeCallback = React.useCallback((selectedPrinterType: string) => {
        setFormValues(prevState => ({
            ...prevState,
            printerType: selectedPrinterType
        }));
    }, []);

    const onChangePrinterName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            printerName: e.target.value || ""
        }));
    }

    const onChangePrinterShortName = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            printerShortName: e.target.value || ""
        }));
    }

    const onChangeHost = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            host: e.target.value || ""
        }));
    }

    const onChangePort = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();

        setFormValues(prevState => ({
            ...prevState,
            port: e.target.value || ""
        }));
    }

    const onClickSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        clickSubmitCallback(formValues);
    }

    return (
        <Stack
            autoComplete="off"
            component="form"
            direction="column"
            spacing={2}
            sx={{
                paddingTop: 2
            }}
            >
            <PrinterTypeDropdown
                selectedType={formValues.printerType}
                setSelectedTypeCallback={setSelectedPrinterTypeCallback}
                />
            <TextField
                id="printerName"
                label="Name"
                onChange={onChangePrinterName}
                value={formValues.printerName}
                />
            <TextField
                id="printerShortName"
                label="Short Name"
                onChange={onChangePrinterShortName}
                value={formValues.printerShortName}
                />
            <TextField
                id="host"
                label="Host"
                onChange={onChangeHost}
                value={formValues.host}
                />
            <TextField
                id="port"
                label="Port"
                onChange={onChangePort}
                value={formValues.port}
                />
            <Button
                color="primary"
                onClick={onClickSubmit}
                type="submit"
                variant="contained"
                >
                Save
            </Button>
        </Stack>
    );
}