import { AxiosInstance } from "axios";
import { ApiService } from "./apiService";
import {CancelRescanRequest, CreateRescanRequest} from "types/dsp/auditRescan";

export class AuditRescanService extends ApiService {
    super(api: AxiosInstance) { }

    createRescan(createRescanRequests: CreateRescanRequest[]): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("api/dsp/audit/rescan", createRescanRequests);

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        });
    }

    cancelRescan(cancelRescanRequests: CancelRescanRequest[]): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.api.post("/api/dsp/audit/rescan/cancel", cancelRescanRequests);

                if (response.status !== 200) {
                    throw new Error(`Unexpected status code: ${response.status}`);
                }

                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }
}