import { List, Stack } from "@mui/material";
import { WritebackRadioButton } from "./radioButton";

export function FormRadioGroup(): JSX.Element {
    return (
        <List
            component={Stack}
            direction="row"
            spacing={1}
            sx={{
                ".MuiListItemButton-root": {
                    border: "2px solid #E6E3ED",
                    borderRadius: 2
                }
            }}
            >
            <WritebackRadioButton
                primary="Real Time Write Back"
                secondaryText={
                    `Ideal for doing small batch cycle counts and performing audits during
                    business hours. On hand quantities and change logs will be updated in
                    LightYear in real time as soon as parts are reconciled.`
                }
                value="false"
                />
            <WritebackRadioButton
                primary="Bulk Write Back (For Physicals)"
                secondaryText={
                    `Ideal for doing full inventory audits outside of business hours. No
                    changes will be made in LightYear until the audit is marked as finished
                    (all bins scanned and all variances addressed).`
                }
                value="true"
                />
        </List>
    )
}