import { MenuItem } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { MenuItemBaseProps } from "./type";

type MenuItemBaseComponentProps = MenuItemBaseProps & {
    onClickSuper: () => void
}

export function MenuItemBase({
    label,
    navigationPath,
    onClickSuper,
}: MenuItemBaseComponentProps): JSX.Element {
    const navigate = useNavigate();

    const onClickMenuItem = (): void => {
        navigate(navigationPath);
        
        if (onClickSuper) {
            onClickSuper();
        }
    }

    return (
        <MenuItem onClick={onClickMenuItem}>
            {label}
        </MenuItem>
    );
}