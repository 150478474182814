import React from "react";
import { NavButtonBase } from "../../button";
import { Menu, MenuList } from "@mui/material";
import { MenuItemBase, MenuItemBaseProps } from "./item";

type MenuButtonBaseProps = {
    icon: React.ReactNode
    id: string
    menuItems: MenuItemBaseProps[]
    text: string
    secondaryText?: string
}

export function MenuButtonBase({
    icon,
    id,
    menuItems,
    text,
    secondaryText
}: MenuButtonBaseProps): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [menuItemComponents, setMenuItemComponents] = React.useState<JSX.Element>(<></>);

    const isOpenMemo = React.useMemo((): boolean => Boolean(anchorEl), [anchorEl]);

    React.useEffect(() => {        
        setMenuItemComponents(() => {
            if (!menuItems) {
                return <></>;
            }
            
            return (
                <>{
                    menuItems.map((menuItem: MenuItemBaseProps, index: number): JSX.Element => {
                        return (
                            <MenuItemBase
                                key={index}
                                label={menuItem.label}
                                navigationPath={menuItem.navigationPath}
                                onClickSuper={onClose}
                            />
                        );
                    })}
                </>
            );
        });
    }, [menuItems]);

    const onClickMenuButton = (e: React.MouseEvent<HTMLDivElement>): void => {
        setAnchorEl(e.currentTarget);
    }

    const onClose = (): void => {
        setAnchorEl(null);
    }

    return (
        <>
            <NavButtonBase
                id={id}
                icon={icon}
                isMenuButton={true}
                text={text}
                secondaryText={secondaryText}
                onClickNav={onClickMenuButton}
                />
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                id={`${id}-menu`}
                MenuListProps={{ "aria-labelledby": id }}
                open={isOpenMemo}
                onClose={onClose}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "top",
                }}
                >
                    <MenuList>
                        {menuItemComponents}
                    </MenuList>
            </Menu>
        </>
    );
}