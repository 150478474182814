const defaultUrl = "";

type ReportingState = {
    url: string;
};

type Action = 
    | { type: 'SET_URL'; payload: string }

export const initialState: ReportingState = {
    url: defaultUrl,
};

export const ReportingReducer = (state: ReportingState, action: Action): ReportingState => {
    switch (action.type) {
        case 'SET_URL':
            return {
                ...state,
                url: action.payload,
            };
        default:
            return state;
    }
};