import {Button, Chip} from "@mui/material";
import {useDspAuditVarianceContext} from "../../context";

export function CancelRescanButton(): JSX.Element {
    const { selectedRescan, cancelRescan } = useDspAuditVarianceContext();

    const onClickSelectRescans = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        const selectedRescans = [...selectedRescan];
        cancelRescan(selectedRescans);
    }

    return (
        <Button
            onClick={onClickSelectRescans}
            variant="outlined"
        >
            <Chip label={selectedRescan.size} />&nbsp;Cancel Rescan
        </Button>
    );
}