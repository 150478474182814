import { Box } from "@mui/material";
import React from "react";

import "./style/icon.css";
import { useNavBarContext } from "../context";

export function NavBarTitle(): JSX.Element {
    const { isOpen } = useNavBarContext();
    const [className, setClassName] = React.useState("icon-small");

    React.useEffect(() => {
        setClassName(() => {
            switch (isOpen) {
                case true:
                    return "icon-full";
                case false:
                    return "icon-small";
            }
        })
    }, [isOpen]);

    return (<Box className={className} />);
}