import React from 'react';
import { SettingsTabType } from './types';
import { settingsReducer, SettingsState } from './reducer';

interface SettingsContextProps {
    selectedTab: SettingsTabType,
    selectTab: (tab: SettingsTabType) => void
}

const initialSettingsState: SettingsState = {
    selectedTab: "parts-print"
}

const SettingsContext = React.createContext<SettingsContextProps | undefined>(undefined);

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(settingsReducer, initialSettingsState);

    const selectTabCallback = React.useCallback((tab: SettingsTabType) => {
        dispatch({ type: 'SET_TAB', payload: tab });
    }, [dispatch]);

    return (
        <SettingsContext.Provider value={{
                selectedTab: state.selectedTab,
                selectTab: selectTabCallback,
            }}
            >
            {children}
        </SettingsContext.Provider>
    );
};

export const useSettingsContext = (): SettingsContextProps => {
    const context = React.useContext(SettingsContext);

    if (!context) {
        throw new Error('useSettingsContext must be used within a SettingsProvider');
    }
    
    return context;
};