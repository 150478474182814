import React from "react";
import { useAuth } from "../../providers";
import { Outlet } from "react-router-dom";
import { UnauthenticatedView } from "../unauthenticatedView";
import { Box, Container } from "@mui/material";
import { useNavBarContext } from "../../navBar/sideBar/context";

export function ContentContainer(): JSX.Element {
    const { isLoggedIn } = useAuth();
    const [component, setComponent] = React.useState(<UnauthenticatedView />);
    const { drawerWidth, isOpen } = useNavBarContext();

    React.useEffect(() => {
        setComponent(() => {
            switch (isLoggedIn) {
                case true:
                    return (<Outlet />);
                case false:
                    return (<UnauthenticatedView />);
            }
        });
    }, [isLoggedIn]);

    return (
        <Box
            sx={{
                flexGrow: 1,
                marginLeft: isOpen ? `${drawerWidth}px` : "65px",
            }}
            >
            <Container
                maxWidth="xl"
                sx={{ minWidth: "1152px"}}
                >
                {component}
            </Container>
        </Box>
    );;
}