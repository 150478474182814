import { NavButtonBase } from "../../base";
import { useLogInContext } from "./context";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export function LogInButton(): JSX.Element {
    const { handleLoginPopup } = useLogInContext();

    return (
        <NavButtonBase
            icon={
                <PersonOutlineOutlinedIcon
                color="inherit"
                />
            }
            text="Log In"
            onClickNav={handleLoginPopup}
            />
    );
}