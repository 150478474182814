import React from "react";
import { IconButton } from "@mui/material";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { useNavBarContext } from "../../../context";

export function NavToggleButton(): JSX.Element {
    const { isOpen, toggleIsOpen } = useNavBarContext();
    const [component, setComponent] = React.useState(<></>);

    React.useEffect(() => {
        setComponent(() => {
            switch (isOpen) {
                case true:
                    return <ChevronLeftOutlined />;
                case false:
                    return <ChevronRightOutlined />;
            }
        });
    }, [isOpen]);

    return (
        <IconButton
            onClick={toggleIsOpen}
            size="small"
            sx={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #E6E3ED",
                left: isOpen ? "220px" : "45px",
                position: "absolute",
                top: "15px",
                transition: "left 0.2s ease-in-out",
                zIndex: 99999999,
                "&:hover": {
                    backgroundColor: "#E6E3ED"
                }
            }}
            >
            {component}
        </IconButton>
    );
}