import { List } from "@mui/material";
import { AdminButton, PartsButton, SettingsButton, VehiclesButton } from "./baseLevel";
import { ReportingButton } from "./baseLevel/reporting";

export function NavButtonsView(): JSX.Element {
    return (
        <List>
            <ReportingButton />
            <PartsButton />
            <VehiclesButton />
            <SettingsButton />
            <AdminButton />
        </List>
    );
}